import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Ref forwarding with React function components and Typescript",
  "description": "While ref forwarding is well explained in the official documentation,\nit can be confusing to type it correctly with function components.\n",
  "meta": {
    "title": "Ref forwarding with React function components and Typescript",
    "description": "Ref forwarding can be confusing to type it correctly with function components."
  },
  "tags": ["React", "TypeScript", "Programming", "HowTo"],
  "publishedAt": "2021-03-29",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`While ref forwarding is well explained in the official documentation,
it can be confusing to type it correctly with function components.`}</em></p>
    <hr></hr>
    <h3>{`1. Typing the reference to be forwarded`}</h3>
    <p>{`The reference is created with the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/refs-and-the-dom.html#creating-refs"
      }}><inlineCode parentName="a">{`createRef`}</inlineCode></a>{` function.`}</p>
    <p>{`Unlike the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#useref"
      }}><inlineCode parentName="a">{`useRef`}</inlineCode></a>{` hook,
which creates a `}<inlineCode parentName="p">{`MutableRefObject`}</inlineCode>{` whose `}<inlineCode parentName="p">{`.current`}</inlineCode>{` property might be initialized
with an `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` value, `}<inlineCode parentName="p">{`createRef`}</inlineCode>{` returns an `}<a parentName="p" {...{
        "href": "https://github.com/facebook/react/blob/master/packages/react/src/ReactCreateRef.js#L17"
      }}>{`immutable`}</a>{`
`}<inlineCode parentName="p">{`RefObject`}</inlineCode>{` so you don't need to bother with the initial value type being different than the final value type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const refTitle: React.RefObject<Text> = React.createRef<Text>();
// In real life, you don't need to explicitly type refTitle
// because of Typescript inference.
`}</code></pre>
    <p>{`In this example the reference will point to a `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component therefore the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component
is passed to `}<inlineCode parentName="p">{`createRef`}</inlineCode>{` as a `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/2/generics.html#working-with-generic-type-variables"
      }}>{`type argument`}</a>{`
to explicitly `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/v16/index.d.ts#L792"
      }}>{`type`}</a>{`
the referenced value.`}</p>
    <hr></hr>
    <h3>{`2. Forwarding the reference to the child component`}</h3>
    <p>{`With the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components"
      }}>{`ref attribute`}</a>{`
of the child component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const ParentComponent: React.FC = () => {
  const refTitle = React.createRef<Text>();
  return <ChildComponent ref={refTitle} title="Hello, World!" />;
};
`}</code></pre>
    <h3>{`3. Assigning the forwarded ref inside the child component`}</h3>
    <p>{`As said in the documentation:`}</p>
    <blockquote>
      <p parentName="blockquote">{`By default, you may not use the ref attribute on function components because they don`}{`'`}{`t have instances.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`If you want to allow people to take a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` to your function component, you can use `}<a parentName="p" {...{
          "href": "https://reactjs.org/docs/forwarding-refs.html"
        }}><inlineCode parentName="a">{`forwardRef`}</inlineCode></a></p>
    </blockquote>
    <p>{`Then with the `}<inlineCode parentName="p">{`forwardRef`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`interface ChildComponentProps {
  title: string;
}

const ChildComponent = React.forwardRef<Text, ChildComponentProps>(
  (
    { title }: ChildComponentProps,
    ref: React.ForwardedRef<Text>
  ): JSX.Element => {
    return <Text ref={ref}>{title}</Text>;
  }
);

// Name component for debugging purpose and prevent eslint warning.
ChildComponent.displayName = 'ChildComponent';
`}</code></pre>
    <p>{`Now there's an immutable `}<inlineCode parentName="p">{`RefObject`}</inlineCode><sup><a parentName="p" {...{
          "href": "https://reactjs.org/docs/refs-and-the-dom.html#creating-refs"
        }}>{`?`}</a></sup>{` available
in the `}<inlineCode parentName="p">{`ParentComponent`}</inlineCode>{` that points to the `}<inlineCode parentName="p">{`Text`}</inlineCode>{` component inside the `}<inlineCode parentName="p">{`ChildComponent`}</inlineCode>{`.`}</p>
    <hr></hr>
    <h3>{`Concrete example`}</h3>
    <p>{`Here is an example with React Native where you can use ref forwarding to focus the device's screen reader on a text once the whole parent component is ready:`}</p>
    <gist id="jexperton/e50ccc04a2a786f010e401fb86164590" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      